import {
  MarkerClusterer,
  MarkerUtils,
} from '@googlemaps/markerclusterer'

// eslint-disable-next-line no-undef
const drawCircle = (map, lat, lng, radius) => new google.maps.Circle({
  strokeColor: '#ed1c24',
  strokeOpacity: 0.2,
  strokeWeight: 2,
  fillColor: 'blue',
  fillOpacity: 0.1,
  map,
  zIndex: 1,
  center: { lat, lng },
  radius: radius * 1000,
})
const createResetZoomButton = (map, resetZoomHandler) => {
  const buttonDiv = document.createElement('div')
  buttonDiv.style.backgroundColor = '#fff'
  buttonDiv.style.border = '2px solid #fff'
  buttonDiv.style.borderRadius = '3px'
  buttonDiv.style.boxShadow = '0 2px 6px rgba(0,0,0,.3)'
  buttonDiv.style.cursor = 'pointer'
  buttonDiv.style.margin = '10px'
  buttonDiv.style.padding = '5px'
  buttonDiv.innerHTML = 'Reset Zoom'

  buttonDiv.addEventListener('click', resetZoomHandler)

  // eslint-disable-next-line no-undef
  map.controls[google.maps.ControlPosition.TOP_RIGHT].push(buttonDiv)
}

const devicesMarkerFunc = (map, devicesPaths, svgMarker, selectedSvgMarker, selectedDeviceIds, onMarkerClick) => {
  const markers = []
  devicesPaths.forEach(device => {
    let markerColor
    if (selectedDeviceIds.length === 0) {
      markerColor = { ...svgMarker }
    } else if (selectedDeviceIds.some(d => d.id === device.id)) {
      markerColor = { ...selectedSvgMarker }
    } else {
      markerColor = { ...svgMarker }
    }

    // eslint-disable-next-line no-undef
    const marker = new google.maps.Marker({
      position: device,
      title: device.name,
      id: device.id,
      icon: markerColor,
    })
    marker.device = device

    // eslint-disable-next-line no-undef
    google.maps.event.addListener(marker, 'click', () => onMarkerClick(device))
    markers.push(marker)
  })
  return markers
}

const markerclusterer = (markers, maps) => {
  // eslint-disable-next-line no-new
  const cluster = new MarkerClusterer({
    markers,
    map: maps,
    renderer: {
      render({ count, position }, stats, map) {
        // change color if this cluster has more markers than the mean cluster
        const color = count >= Math.max(10, stats.clusters.markers.mean) ? '#f08c0a' : '#7d0af0'
        // create svg literal with fill color
        const svg = `<svg fill="${color}" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 240 240" width="50" height="50">
                          <circle cx="120" cy="120" opacity=".6" r="70" />
                          <circle cx="120" cy="120" opacity=".3" r="90" />
                          <circle cx="120" cy="120" opacity=".2" r="110" />
                          <text x="50%" y="50%" style="fill:#fff" text-anchor="middle" font-size="50"
                          dominant-baseline="middle" font-family="roboto,arial,sans-serif">${count}</text>
                          </svg>`
        const title = `Cluster of ${count} markers`
        // adjust zIndex to be above other markers
        // eslint-disable-next-line no-undef
        const zIndex = Number(google.maps.Marker.MAX_ZINDEX) + count
        if (MarkerUtils.isAdvancedMarkerAvailable(map)) {
          // create cluster SVG element
          const parser = new DOMParser()
          const svgEl = parser.parseFromString(svg, 'image/svg+xml').documentElement
          svgEl.setAttribute('transform', 'translate(0 25)')
          const clusterOptions = {
            map,
            position,
            zIndex,
            title,
            content: svgEl,
          }
          // eslint-disable-next-line no-undef
          return new google.maps.marker.AdvancedMarkerElement(clusterOptions)
        }
        const clusterOptions = {
          position,
          zIndex,
          title,
          icon: {
            url: `data:image/svg+xml;base64,${btoa(svg)}`,
            // eslint-disable-next-line no-undef
            anchor: new google.maps.Point(25, 25),
          },
        }
        // eslint-disable-next-line no-undef
        return new google.maps.Marker(clusterOptions)
      },
    },

  })
  return cluster
}

const moveToLocation = (map, lat, lng) => {
  const position = { lat, lng }
  map.setCenter(position)
  map.setZoom(50)
  return map
}

const createColorMarker = (svgMarker, color) => {
  const selectedColor = svgMarker
  selectedColor.fillColor = color
  return selectedColor
}

export {
  drawCircle,
  createResetZoomButton,
  devicesMarkerFunc,
  markerclusterer,
  moveToLocation,
  createColorMarker,
}
