<template>
  <div>
    <b-modal
      ref="add-time-slots"
      centered
      hide-footer
      hide-header
      class="position-relative custom-modal"
      size="lg"
      no-close-on-backdrop
      no-close-on-esc
      @hide="hideModal"
    >
      <div class="m-2">
        <validation-observer ref="addVenue">
          <b-form-row class="">
            <!-- Venue Operating Hours -->
            <b-col cols="12">
              <div class="headingVenue d-flex justify-content-center">
                Time Slots in UTC
              </div>
              <div
                class="closediv"
              >
                <b-button
                  variant="transparent"
                  class="closebtn"
                  @click="hideModal()"
                >
                  <feather-icon
                    icon="XIcon"
                    class="cursor-pointer"
                    size="16"
                  />
                </b-button>
              </div>
            </b-col>
            <b-row
              v-for="(slot) in datesForTimeSlots"
              :key="slot.id"
              lg="12"
              md="12"
              xl="12"
              sm="12"
              class="my-1"
            >
              <b-col cols="1">
                <b-badge
                  pill
                  variant="success"
                  class="ml-1 fs-1"
                >
                  <span class="datesText">{{ formatDateToDDMMYYYY(slot) }}</span>
                </b-badge>
              </b-col>
            </b-row>
            <!-- Time slot selector (Rendered multiple times) -->
            <b-col
              v-for="(slot, index) in timeSlots"
              :key="slot.id"
              lg="12"
              md="12"
              xl="12"
              sm="12"
              class="my-1 parentDiv"
            >
              <b-row class="RowSlots">
                <b-col
                  lg="6"
                  md="5"
                  xl="6"
                  sm="5"
                  xs="5"
                  class="StartTimeCol"
                >
                  <!-- Start Time selector-->
                  <ValidationProvider
                    name="Start Time"
                    vid="screen-start-time"
                    rules="required"
                  >
                    <b-form-group
                      label="Start Time UTC"
                      class="label mb-0"
                    >
                      <b-form-timepicker
                        v-model="slot.start_time"
                        placeholder="00:00:00"
                        variant="primary"
                        locale="en"
                        :show-seconds="true"
                        :hour12="false"
                      />
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <b-col
                  lg="5"
                  md="5"
                  xl="5"
                  sm="5"
                  xs="5"
                  class="ml-2 endTimeCol"
                >
                  <!-- End Time selector-->
                  <ValidationProvider
                    name="End Time"
                    vid="screen-end-time"
                    rules="required"
                  >
                    <b-form-group
                      label="End Time UTC"
                      class="label mb-0"
                    >
                      <b-form-timepicker
                        v-model="slot.end_time"
                        placeholder="00:00"
                        variant="primary"
                        locale="en"
                        :show-seconds="true"
                        :hour12="false"
                        :disabled="true"
                      />
                    </b-form-group>
                  </ValidationProvider>
                </b-col>
                <b-button
                  v-if="index !== 0"
                  v-ripple.400="'rgba(113, 102, 240, 0.15)'"
                  variant="flat-danger"
                  class="btn-icon rounded-circle m-0 mr-8 mt-2"
                  @click="removeTimeSlot(index)"
                >
                  <feather-icon icon="XIcon" />
                </b-button>
              </b-row>
              <b-row>
                <b-col>
                  <hr class="mb-2 horizontal-line mx-4">
                </b-col>
              </b-row>
            </b-col>
            <b-col
              lg="12"
              xl="12"
              md="12"
              sm="12"
              class="d-flex justify-content-center"
            >
              <span
                class="form-group cursor-pointer"
                @click="addNewTimeSlot"
              >
                <small class="text-danger text-center">{{ errorMessage ? errorMessage : null }}</small>
              </span>
            </b-col>
            <!-- Button to add new time slot -->
            <b-col
              lg="12"
              xl="12"
              md="12"
              sm="12"
              class="d-flex justify-content-center"
            >
              <span
                class="form-group cursor-pointer"
                @click="addNewTimeSlot"
              >
                <feather-icon icon="PlusIcon" />
                Add New Time Slot
              </span>
            </b-col>

            <!-- Submit Button -->
            <b-col
              lg="12"
              xl="12"
              md="12"
              sm="12"
              class="d-flex justify-content-center align-items-center"
            >
              <div class="form-group w-50">
                <b-button
                  block
                  variant="primary"
                  :disabled="spinner"
                  type="submit"
                  @click="submitTimeSlots"
                >
                  <div
                    v-if="spinner"
                    class="spinner"
                  >
                    <b-spinner small />
                    <span class="sr-only">Loading...</span>
                  </div>
                  Add
                </b-button>
              </div>
            </b-col>
          </b-form-row>
        </validation-observer>
      </div>
    </b-modal>
  </div>
</template>

<script>
import {
  BButton,
  BModal,
  BFormGroup,
  VBModal,
  BCol,
  BSpinner,
  BFormRow,
  BFormTimepicker,
  BBadge,
  BRow,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import 'vue-phone-number-input/dist/vue-phone-number-input.css'
import { required } from '@validations'
import Ripple from 'vue-ripple-directive'
import { formatDateToDDMMYYYY, calculateEndTime } from '@/common/global/functions'

export default {
  components: {
    BFormRow,
    BRow,
    BButton,
    BFormGroup,
    BModal,
    BCol,
    BSpinner,
    ValidationProvider,
    ValidationObserver,
    BFormTimepicker,
    BBadge,
  },
  directives: {
    'b-modal': VBModal,
    Ripple,
  },
  props: {
    datesForTimeSlots: {
      type: Array,
      required: true,
    },
    arrayOfDatesAndTimeSlots: {
      type: Array,
      required: true,
    },
    totalDuration: {
      type: Number,
      required: true,
    },
  },
  data() {
    return {
      spinner: false,
      required,
      timeSlots: [{ start_time: null, end_time: null }],
      finalResult: [],
      errorMessage: '',
    }
  },
  computed: {
    venueList() {
      return this.$store.getters['venues/getVenueTypeList']
    },
    userRole() {
      return this.$store.getters['user/getUserRole']
    },
    countries() {
      return this.$store.getters['devices/getCountries']
    },
    endTimeField() {
      if (!this.venue.start_time) {
        return false
      }
      return true
    },
  },
  watch: {
    timeSlots: {
      handler(newTimeSlots) {
        this.errorMessage = ''
        newTimeSlots.forEach((timeSlot, index) => {
          if (timeSlot.start_time) {
            this.timeSlots[index].end_time = calculateEndTime(timeSlot.start_time, this.totalDuration)
          }
        })
      },
      deep: true,
    },
  },
  methods: {
    formatDateToDDMMYYYY,
    resetDataWhenModalClosed() {
      this.errorMessage = ''
      this.timeSlots = [{ start_time: null, end_time: null }]
      this.valueToBeSet = []
    },

    show() {
      this.valueToBeSet = this.arrayOfDatesAndTimeSlots
      this.timeSlots = [{ start_time: null, end_time: null }]
      this.$refs['add-time-slots'].show()
    },

    hideModal() {
      this.$emit('notSetTimeSlot')
      this.resetDataWhenModalClosed()
      this.$refs['add-time-slots'].hide()
    },

    async addNewTimeSlot() {
      if (!this.timeSlots[this.timeSlots.length - 1].start_time) {
        this.errorMessage = 'Please fill in the slot before adding a new one.'
        return
      }
      if (!this.validateTimeSlots()) {
        return
      }
      this.timeSlots.push({ start_time: null, end_time: null })
    },

    removeTimeSlot(index) {
      this.timeSlots.splice(index, 1)
    },

    submitTimeSlots() {
      if (this.validateTimeSlots()) {
        this.datesForTimeSlots.forEach(element => {
          const dateExists = this.valueToBeSet.find(d => d.key === element)

          if (dateExists) {
            // If the date already exists, append new time slots to the existing value
            this.timeSlots.forEach(slot => {
              dateExists.value.push(slot)
            })
          } else {
            // If the date doesn't exist, push a new object with the date and time slots
            this.valueToBeSet.push({
              key: element,
              value: [...this.timeSlots],
            })
          }
        })
        this.$emit('addDatesWithTimeSlotsToArray', this.valueToBeSet)
        this.hideModal()
      }
    },

    isTimeSlotOverlapping(slot1, slot2) {
      return slot1.start_time <= slot2.end_time && slot1.end_time > slot2.start_time
    },

    validateTimeSlots() {
      // Check if the last time slot is filled
      if (!this.timeSlots[this.timeSlots.length - 1].start_time) {
        this.errorMessage = 'Slot must be filled before submitting.'
        return false
      }

      // eslint-disable-next-line no-plusplus
      for (let i = 0; i < this.timeSlots.length; i++) {
        // eslint-disable-next-line no-plusplus
        for (let j = i + 1; j < this.timeSlots.length; j++) {
          if (this.isTimeSlotOverlapping(this.timeSlots[i], this.timeSlots[j])) {
            this.$nextTick(() => {
              this.errorMessage = 'Time slots overlap! Please adjust the times.'
            })
            return false
          }
        }
      }

      // Check for overlapping time slots if there are any existing dates
      if (this.valueToBeSet.length > 0) {
        const hasOverlap = this.datesForTimeSlots.some(element => {
          // Find matching dates
          const matchingDates = this.valueToBeSet.filter(d => d.key === element)
          // If matchingDates has any items, check for overlapping time slots
          return matchingDates.some(dateInstance => dateInstance.value.some(existingSlot => this.timeSlots.some(newSlot => {
            if (this.isTimeSlotOverlapping(newSlot, existingSlot)) {
              this.errorMessage = `Time slots overlap for date ${element}`
              return true // Found overlapping time slots
            }
            return false
          })))
        })

        if (hasOverlap) {
          return false // Stop validation if overlap is found
        }
      }

      return true // Return true if all validations pass
    },
  },

}
</script>
    <style src="vue-it-bigger/dist/vue-it-bigger.min.css"></style>
    <style lang="scss">
    .vs__dropdown-menu{
      max-height: 250px !important;
    }
    .form{
      padding-inline: 10px;
    }
    .add{
      position: relative;
    }
    .upload{
      position: absolute;
      bottom: 20px;
      right: 54px;
    }
    .head{
      font-size: 14px;
      line-height: 24px;
      color: #1f58b5;
      font-weight: 600;
      font-family: "Montserrat";
    }
    .spinner{
      text-align: center;
      z-index: 1070;
    }
    .compaignlabel{
      font-size: 10px;
      line-height: 18px;
      color: #323232;
      font-weight: 600;
      font-family: "Montserrat";
    }
    .headingVenue{
      font-size: 20px;
      color: black;
      font-weight: bolder;
    }
    .form-row {
      max-height: 400px !important;
      overflow-y: auto;
      overflow-x: hidden;
    }
    .form-row::-webkit-scrollbar {
      width: 3px;
    }
    .form-row::-webkit-scrollbar-track {
      background-color: #e4e4e4;
      border-radius: 50px;
    }

    .form-row::-webkit-scrollbar-thumb {
      background-color: #4c92fc;
      border-radius: 50px;
    }
    .custom-modal{
      width: 800px !important;
    }
    .datesText{
      font-size: larger;
    }
    .pac-container {
      z-index: 1041;
    }

    .horizontal-line {
      width: 100%;
      height: 1px;
      border: none;
      border-top: .5px solid #ffffff;
      margin-top: 1rem;
    }

    @media (max-width: 990px) {
    .enter-manually{
      width: 100% !important;
      padding-top: 5px;
    }
    }
    @media (max-width: 590px) {
      .endTimeCol{
        margin-left: 0px !important;
        margin-top: 16px !important;
        width: 80%;
      }
      .horizontal-line {
        width: 75%;
        height: 1px;
        border: none;
        border-top: .5px solid #000;
        margin-top: 1rem;
      }
      .StartTimeCol{
        width: 80%;
      }
      .RowSlots{
        width: 80%;
        margin-left: 5rem;
        margin-bottom: 10px;
      }
      .parentDiv{
        display: flex;
        justify-content: center;
        flex-direction: column;
      }
      .btn-icon{
        margin-top: 2.5rem !important;
      }
      }

    @media (max-width: 550px) {
    .enter-manually{
      font-size: 12px;
    }
    .headingVenue{
      font-size: 18px;
    }
    .modal{
      padding-left: 1rem !important;
      padding-right: 1rem !important;
    }
    .datesText{
      font-size: smaller;
    }
    }

    </style>
